// Others
import _ from 'lodash';


function getDefaultSessionObj() {
    return {
        sessionId: '',
        status: 'On-Going',
        company: '',
        companyId: '',
        storageLocation: '',
        storageLocationId: '',
        description: '',
        userId: '',
        assets: [],
        dateCreated: null,
        createdBy: '',
        dateUpdated: null,
        updatedBy: '',
        dateCancelled: null,
        cancelledBy: ''
    };
}

function getDefaultInventoryAssetObj(assetType) {
    return {
        assetType: assetType.name,
        assetTypeId: assetType.id,
        actualQuantity: 0,
        totalRemaining: 0,
        description: '',
    }
}

function cleanupFields(session) {
    let cleanedObj = { ...session };

    delete cleanedObj['Date Created'];
    delete cleanedObj['Date Updated'];
    delete cleanedObj['Date Cancelled'];
    delete cleanedObj['Total'];
    delete cleanedObj['Assets'];
    delete cleanedObj['_showDetails'];

    return cleanedObj;
}

function isExistingAssetType(assets, assetTypeId) {
    return (
        !_.isEmpty(assets) &&
        _.find(assets, { 'assetTypeId': assetTypeId })
    );
}

function getTotalActualQuantity(assets) {
    let actualQuantity = 0;

    if (!_.isEmpty(assets)) {
        _.forEach(assets, asset => {
            actualQuantity += parseInt(asset.actualQuantity);
        });
    }

    return actualQuantity;
}

function getAssetsDisplay(assets) {
    let assetsDisplay = '';

    if (!_.isEmpty(assets)) {
        _.forEach(assets, asset => {
            assetsDisplay += asset.assetType + ' - ' + asset.actualQuantity + '; ';
        });
    }

    return assetsDisplay;
}

function hasAssetType(assets, assetTypeId) {
	let inventoryAsset = _.find(assets, o => {
		return o.assetTypeId === assetTypeId;
	});
	return !_.isEmpty(inventoryAsset);
}

export const InventorySessionUtil = {
    getDefaultSessionObj,
    getDefaultInventoryAssetObj,
    cleanupFields,
    isExistingAssetType,
    getTotalActualQuantity,
    getAssetsDisplay,
    hasAssetType
}