import axios from 'axios';
import config from '@/config/env-constants';

function getProjectId() {
    if (config.currEnv === 'pallet-pooling') {
        return `${config.currEnv}`;
    } else {
        return `ayun-quantity-${config.currEnv}`;
    }
}

export default {
    baseUrl: `https://us-central1-${getProjectId()}.cloudfunctions.net`,

    getInventorySessions(filterBy, view, currUserId) {
        const url = `${this.baseUrl}/getInventorySessions`;
        return axios.post(url, {
            view: view,
            currUserId: currUserId,
            filterBy: JSON.stringify(filterBy)
        });
    },

    saveInventorySession(inventorySession, currUserId, currTimeStamp) {
        let url = `${this.baseUrl}/saveInventorySession`;
        return axios.post(url, {
            currUserId: currUserId,
            currTimeStamp: currTimeStamp,
            inventorySession: JSON.stringify(inventorySession)
        });
    },

    cancelInventorySession(inventorySession, currUserId, currTimeStamp) {
        let url = `${this.baseUrl}/cancelInventorySession`;
        return axios.post(url, {
            currUserId: currUserId,
            currTimeStamp: currTimeStamp,
            inventorySession: JSON.stringify(inventorySession)
        });
    },

    markInventorySessionAsDone(inventorySession, currUserId, currTimeStamp) {
        let url = `${this.baseUrl}/markInventorySessionAsDone`;
        return axios.post(url, {
            currUserId: currUserId,
            currTimeStamp: currTimeStamp,
            inventorySession: JSON.stringify(inventorySession),
        });
    },

}